@import url("https://fonts.cdnfonts.com/css/satoshi");

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  20% {
    background-position: 20% 80%;
  }
  40% {
    background-position: 80% 20%;
  }
  60% {
    background-position: 100% 50%;
  }
  80% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient {
  background: radial-gradient(circle, #1b1b2f, #6f13a8, #169462, #af7e4c);
  background-size: 500% 500%;
  animation: gradientAnimation 40s ease-in-out infinite;
}

.nav-blur {
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}

.nav-scroll-pill {
  background-color: hsla(158, 23%, 18%, 0.7);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}

